.footer {
  position: absolute;
  height: 10vh;
  bottom: 0%;
  left: 0%;
  width: 100%;
  text-align: center;
  padding: 1%;
  background-color: #dae4ee;
}

a {
  text-decoration: none;
}

input.form-control {
  padding: 0.3rem;
}

textarea.form-control {
  min-height: calc(15em + 0.75rem + 2px);
}

.nav-link {
  padding-right: 1.5rem !important;
}
